import React from "react";
import "../index.css";
import {BrowserRouter as Router} from "react-router-dom";
import {
	MDBAnimation,
	MDBBox,
	MDBCarousel,
	MDBCarouselInner, MDBCarouselItem,
	MDBCol,
	MDBContainer,
	MDBIcon,
	MDBMask,
	MDBRow,
	MDBView
} from "mdbreact";
import { Element } from 'react-scroll'

class About extends React.Component {
	
	render(){
		return (
			<div id="aboutpage" >
				<Router>
					<MDBView className="top-header text-center">
						<MDBContainer>
							<MDBRow>
								<MDBCol>
									<Element name="aboutpage">
										<h1 id="about-header-text" className="light-text-shadow">
											Wide Range of Hands-on Experience
										</h1>
									</Element>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
						<MDBContainer fluid className="experience-container">
							<MDBRow className="experience-row">
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/267_Python-512.png" className="exp-icon z-depth-1-half"/>
										</div>
										<div className="exp-title">
											<p>
												Python
											</p>
										</div>
										<div className="exp-desc">
											<p>
												From simple task automation to A.I. solutions.
											</p>
										</div>
									</MDBBox>
								</MDBCol>
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<MDBCarousel activeItem={1}
																   length={4}
																   showControls={false}
																   showIndicators={false}
														 			interval={2000}
														 className="top-z caros z-depth-1-half"
											>
												<MDBCarouselInner className="top-z">
													<MDBCarouselItem itemId="1">
														<MDBView>
															<img src="https://img.icons8.com/color/452/nodejs.png" className="exp-icon-carousel top-z"/>
														</MDBView>
													</MDBCarouselItem>
													<MDBCarouselItem itemId="2">
														<MDBView>
															<img src="https://cdn.discordapp.com/attachments/672990161188945955/802732795381809183/typescript.png" className="exp-icon-carousel top-z"/>
														</MDBView>
													</MDBCarouselItem>
													<MDBCarouselItem itemId="3">
														<MDBView>
															<img src="https://angular.io/assets/images/logos/angular/angular.png" className="exp-icon-carousel top-z"/>
														</MDBView>
													</MDBCarouselItem>
													<MDBCarouselItem itemId="4">
														<MDBView>
															<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" className="exp-icon-carousel top-z"/>
														</MDBView>
													</MDBCarouselItem>
												</MDBCarouselInner>
											</MDBCarousel>
										</div>
										<div className="exp-title">
											<p>
												Web Development
											</p>
										</div>
										<div className="exp-desc">
											<p>
												Giving websites life from back to front and back again.
											</p>
										</div>
									</MDBBox>
								</MDBCol>
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<img src="https://cdn.discordapp.com/attachments/672990161188945955/802733611203035156/gcloud.png" className="exp-icon z-depth-1-half"/>
										</div>
										<div className="exp-title">
											<p>
												Google Cloud
											</p>
										</div>
										<div className="exp-desc">
											<p>
												For hosting, database, and computing services.
											</p>
										</div>
									</MDBBox>
								</MDBCol>
							</MDBRow>
							<MDBRow className="experience-row">
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<img src="https://cdn.discordapp.com/attachments/672990161188945955/802737699927883827/agile.png" className="exp-icon z-depth-1-half"/>
										</div>
										<div className="exp-title">
											<p>
												Agile - Scrum Methodology
											</p>
										</div>
										<div className="exp-desc">
											<p>
												Modern development teams require modern development practices.
											</p>
										</div>
									</MDBBox>
								</MDBCol>
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<img src="https://cdn.discordapp.com/attachments/672990161188945955/802740018870616084/book.png" className="exp-icon z-depth-1-half"/>
										</div>
										<div className="exp-title">
											<p>
												Fast-Paced Learning
											</p>
										</div>
										<div className="exp-desc">
											<p>
												Always hungry for knowledge and improvement!
											</p>
										</div>
									</MDBBox>
								</MDBCol>
								<MDBCol className="experience-col">
									<MDBBox>
										<div className="exp-icon-container mx-auto">
											<img src="https://cdn.discordapp.com/attachments/672990161188945955/802740587547721738/mag.png" className="exp-icon z-depth-1-half"/>
										</div>
										<div className="exp-title">
											<p>
												Attention to Detail
											</p>
										</div>
										<div className="exp-desc">
											<p>
												Ensuring quality through extensive unit testing and talking to rubber ducks.
											</p>
										</div>
									</MDBBox>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</MDBView>
				</Router>
			</div>
			);
	}
}
export default About;
