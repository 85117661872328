import React, {useState} from "react";
import {

	MDBCol,
 MDBIcon, MDBBadge, MDBCollapse
} from "mdbreact";
import "../index.css";

function ProjectCard(props) {
	const [collapsed, setCollapsed] = useState(false);
	
	function toggleCollapse() {
		setCollapsed(!collapsed);
	}
	
	return (
		<MDBCol xl="4" lg="5" md="7" sm="10" className="project-card z-depth-1-half" onClick={toggleCollapse}>
			<div className="project-card-header">
				<h1>
					{props.title}
				</h1>
			</div>
			<div className="project-card-image-container">
				<div className="project-card-image"
					 style={{backgroundImage: `url(${props.backgroundUrl})`}}
				/>
			</div>
			<div className="project-card-body">
				<h1 className="project-card-body-text">
					{props.brief}
				</h1>
				
				<hr/>
				<MDBCollapse isOpen={collapsed}>
					
					<p>
						{props.desc1}
					</p>
					<p>
						{props.desc2}
					</p>
				
				</MDBCollapse>
				<MDBIcon icon={collapsed ? "angle-up" : "angle-down"} />
				<div>
					{props.children}
				</div>
			</div>
		
		</MDBCol>
	)
}
export default ProjectCard
// class ProjectCard extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.collapse = React.createRef();
// 		this.state = {
// 			collapseElement: React.createRef(),
// 			collapse: false
// 		}
// 	}
// 	toggleCollapse = collapseElement => () => {
// 		console.log(this.state)
// 		this.setState(prevState => ({
// 			collapse: !prevState.collapse
// 		}));
// 	}
// 	render() {
// 		return (
//
// 		)
// 	}
// }

