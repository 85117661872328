import React, {createRef} from "react";
import {
	MDBMask,
	MDBRow,
	MDBCol,
	MDBView,
	MDBContainer,
	MDBAnimation, MDBIcon, MDBBtn
} from "mdbreact";
import { Link } from 'react-scroll'
import Obfuscate from 'react-obfuscate';
import {BrowserRouter as Router, NavLink} from "react-router-dom";
import "../index.css";


class Header extends React.Component {
	render() {
		return (
			<div id="apppage">
				<Router>
					<MDBView className="top-header">
						<MDBMask className="d-flex justify-content-center align-items-center gradient">
							<MDBContainer fluid>
								<MDBRow>
									<MDBCol className="white-text text-center header-text">
										<MDBAnimation type="fadeIn" delay=".3s">
											<h1 className="h1-responsive font-weight-bold mt-sm-5 hand-font tyler-wannamaker light-text-shadow">
												Tyler Wannamaker
											</h1>
										</MDBAnimation>
										<MDBAnimation type="fadeInUp" delay="1s">
											<h3 className="h3-responsive font-weight-light developer">
												Developer
											</h3>
										</MDBAnimation>
									</MDBCol>
								</MDBRow>
								<Link to="aboutpage" spy={true} smooth={true} duration={600} offset={-50}>
								<MDBRow>
										<MDBCol className="pointer-row">
											<MDBAnimation type="fadeInDown" duration="1s" delay="2s">
												<MDBAnimation type="bounce" infinite duration="2s" delay="4s">
													<MDBIcon icon="angle-down" className="pointer-icon"/>
												</MDBAnimation>
											</MDBAnimation>
										</MDBCol>
								</MDBRow>
								</Link>
							</MDBContainer>
						</MDBMask>
					</MDBView>
					<MDBAnimation type="fadeInDown" delay="2s" id="email-btn-container">
						<Obfuscate email="Tyler@Wannamaker.dev">
							<MDBBtn color="elegant" size="lg" id="email-btn">
								<MDBIcon icon="envelope"/>
							</MDBBtn>
						</Obfuscate>
					</MDBAnimation>
					<MDBAnimation type="fadeInDown" delay="2.5s" id="linkedin-btn-container">
						<a href="https://www.linkedin.com/in/tylerwannamaker/" target="_blank">
							<MDBBtn color="blue" size="lg" id="linkedin-btn">
								<MDBIcon fab icon="linkedin"/>
							</MDBBtn>
						</a>
					</MDBAnimation>
				</Router>
			</div>
		);
	}
}

export default Header;
