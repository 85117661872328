import React, {createRef} from "react";
import {
    MDBMask,
    MDBRow,
    MDBCol,
    MDBView,
    MDBContainer,
    MDBAnimation, MDBBtn, MDBIcon, MDBLink, MDBNavLink
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import Header from "./components/Header";
import About from "./components/About";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

class App extends React.Component {
    
    render() {
        return (
            <div>
                <Header/>
                <About/>
                <Projects/>
                <Footer/>
            </div>
        );
    }
}

export default App;
