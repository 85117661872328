import React, {createRef} from "react";
import {
	MDBMask,
	MDBRow,
	MDBCol,
	MDBView,
	MDBContainer,
	MDBAnimation, MDBIcon, MDBBadge, MDBCollapse
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import "../index.css";
import ProjectCard from "./ProjectCard";
import ProjectCardBadge from "./ProjectTagBadge";
import {Element} from "react-scroll";


class Projects extends React.Component {
	render() {
		return (
			<div id="projectpage">
				<Router>
					<MDBView>
						<MDBContainer>
							<MDBRow>
								<MDBCol>
									<Element name="projectpage">
										<h1 id="project-header-text" className="light-text-shadow">
											A Few Latest and Greatest Projects
										</h1>
									</Element>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
						<MDBContainer fluid>
							<MDBRow around className="project-list-row">
								<ProjectCard
									title="TF+"
									brief="Esport community resource for multifaceted assets and services"
									desc1="This website provides an interface for competitive pick-up games and customizable broadcast graphics for hundreds of end users."
									desc2="Originally created to streamline the user experience over 2 years ago, I have recently started to refine this project to build my experience with React, REST API, and full-stack development."
									backgroundUrl="./tfplus.png"
								>
									<ProjectCardBadge
										color="teal lighten-1"
										text="React"
									/>
									<ProjectCardBadge
										color="success"
										text="NextJS"
									/>
									<ProjectCardBadge
										color="primary"
										text="GCloud Firestore"
									/>
									<ProjectCardBadge
										color="warning"
										text="REST"
									/>
									<ProjectCardBadge
										color="danger"
										text="Full-stack"
									/>
								</ProjectCard>
								<ProjectCard
									title="Gastimate"
									brief="Hackathon submission to parse gas station information from images."
									desc1="Created in less than a day, this challenge submission helps supply an endpoint between inputs, such as dashcams, into useful information."
									desc2="This program derives crucial information, such as types of fuel available, their individual prices, and the gas station brand."
									backgroundUrl="./gastimate.png"
								>
									<ProjectCardBadge
										color="green"
										text="Google Vision API"
									/>
									<ProjectCardBadge
										color="deep-orange"
										text="Data Parsing"
									/>
								</ProjectCard>
								<ProjectCard
									title="Sentiment Analysis Buffet"
									brief="Research project to compare practical implementations of large-scale sentiment analysis."
									desc1="Through a small team led by myself, we explore multiple ways to derive sentiment of online reviews."
									desc2="With a dataset of over 50,000 reviews, we implement and compare methods such as Expert System, Naive Bayes, and SVM."
									backgroundUrl="./sent.png"
								>
									<ProjectCardBadge
										color="yellow darken-3"
										text="Multi-threaded C"
									/>
									<ProjectCardBadge
										color="indigo"
										text="Leadership"
									/>
									<ProjectCardBadge
										color="blue"
										text="Python"
									/>
									<ProjectCardBadge
										color="red"
										text="A.I."
									/>
								</ProjectCard>
								<ProjectCard
									title="Head of Production"
									brief="Staff coordinator and lead programmer of live video content, reaching an audience of over 500,000."
									desc1="Implements a graphical interface for use in live broadcast overlays."
									desc2="Works regularly with volunteer staff and competitive teams to coordinate events."
									backgroundUrl="./ncg.png"
								>
									<ProjectCardBadge
										color="indigo"
										text="Leadership"
									/>
									<ProjectCardBadge
										color="danger"
										text="Front-end"
									/>
								</ProjectCard>
								<ProjectCard
									title="Wannamaker.dev"
									brief="My website."
									desc1="Pretty self-explanatory if you ask me."
									desc2=""
									backgroundUrl="./tyler.png"
								>
									<ProjectCardBadge
										color="teal lighten-1"
										text="React"
									/>
								</ProjectCard>
							</MDBRow>
						</MDBContainer>
						<MDBContainer>
							<p className="project-side">
								Project code and résumé only available on request due to privacy and security concerns.
							</p>
						</MDBContainer>
					</MDBView>
				</Router>
			</div>
		);
	}
}

export default Projects;
