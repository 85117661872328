import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; import
    'bootstrap-css-only/css/bootstrap.min.css'; import
    'mdbreact/dist/css/mdb.css';
import App from './App';
import About from "./components/About";
import "./index.css";
import {MDBView} from "mdbreact";
ReactDOM.render(
  <React.StrictMode>
      <MDBView className="gradient">
              <App />
      </MDBView>
  </React.StrictMode>,
  document.getElementById('root')
);
