import {MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow} from "mdbreact";
import Obfuscate from 'react-obfuscate';
function Footer() {
	return (
		<MDBFooter>
			<MDBContainer fluid id="footer" >
				<MDBRow center>
					<MDBCol className="text-center">
						<div className="footer-about">
							<h4>
								Hi, I'm Tyler Wannamaker!
							</h4>
							<h5>
								I love using software and website engineering to solve everyday problems.
							</h5>
							<h5>
								My education spans through 3 universities, with the latest being the Georgia Institute of Technology.
							</h5>
							<h5>
								In my spare time, I like to play instruments and make bread.
							</h5>
							<br/>
						</div>

					</MDBCol>
					<MDBCol className="text-center">
						<h2>
							Like what you see?
							Contact me!
						</h2>
						<br/>
						<h1 className="email-div">
							<MDBIcon icon="envelope" /> <Obfuscate id="email" email="Tyler@Wannamaker.dev"/>
						</h1>
						<h4>"<i>Wanna - Maker</i>"</h4>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</MDBFooter>

	)
}
export default Footer;
